import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaRobot, FaChartLine, FaUserAlt, FaCogs, FaLightbulb, FaShieldAlt, FaDatabase, FaMobileAlt, FaCloud, FaMoneyCheck, FaBrain, FaHandshake } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

// Import your background images
// Import your background images
import bgImage1 from '../assets/webp/bg1.webp';
import bgImage2 from '../assets/webp/bg2.webp';
import bgImage3 from '../assets/webp/bg3.webp';
import bgImage4 from '../assets/webp/bg4.webp';
import bgImage5 from '../assets/webp/bg5.webp';
import bgImage6 from '../assets/webp/bg6.webp';
import bgImage7 from '../assets/webp/bg7.webp';
import bgImage8 from '../assets/webp/bg8.webp';
import bgImage9 from '../assets/webp/bg9.webp';
import bgImage10 from '../assets/webp/bg10.webp';
import bgImage11 from '../assets/webp/bg11.webp';
import bgImage12 from '../assets/webp/bg12.webp';

const cardData = [
  {
    icon: <FaRobot size={40} />,
    title: 'Automate and Optimize',
    frontText: 'Automate and Optimize',
    backText: 'Streamline operations and increase productivity with AI-driven automation. Reduce operational costs and improve efficiency through intelligent systems.',
    backgroundImage: bgImage1,
  },
  {
    icon: <FaChartLine size={40} />,
    title: 'Unlock Insights',
    frontText: 'Unlock Insights',
    backText: 'Leverage advanced analytics to make data-driven decisions and anticipate trends. Transform raw data into actionable insights for better business outcomes.',
    backgroundImage: bgImage2,
  },
  {
    icon: <FaUserAlt size={40} />,
    title: 'Enhance Engagement',
    frontText: 'Enhance Engagement',
    backText: 'Improve customer interactions with personalized experiences and intelligent chatbots. Boost customer satisfaction and loyalty through enhanced service delivery.',
    backgroundImage: bgImage3,
  },
  {
    icon: <FaCogs size={40} />,
    title: 'Tailored AI Solutions',
    frontText: 'Tailored AI Solutions',
    backText: 'Create scalable AI solutions tailored to your industry’s unique challenges. Implement bespoke AI strategies to meet specific business needs.',
    backgroundImage: bgImage4,
  },
  {
    icon: <FaLightbulb size={40} />,
    title: 'Drive Innovation',
    frontText: 'Drive Innovation',
    backText: 'Integrate the latest AI technologies to foster innovation and drive growth. Stay ahead of the competition by continuously evolving with cutting-edge solutions.',
    backgroundImage: bgImage5,
  },
  {
    icon: <FaShieldAlt size={40} />,
    title: 'Enhance Security and Compliance',
    frontText: 'Enhance Security and Compliance',
    backText: 'Use AI to detect and mitigate risks, ensuring compliance with industry standards and regulations. Protect sensitive data and maintain trust with robust security measures.',
    backgroundImage: bgImage6,
  },
  {
    icon: <FaDatabase size={40} />,
    title: 'Data Management',
    frontText: 'Data Management',
    backText: 'Efficiently manage and store your data with advanced AI solutions. Ensure data integrity and accessibility for better decision-making.',
    backgroundImage: bgImage7,
  },
  {
    icon: <FaMobileAlt size={40} />,
    title: 'Mobile Solutions',
    frontText: 'Mobile Solutions',
    backText: 'Develop cutting-edge mobile applications powered by AI. Enhance user experience and engagement with intelligent features.',
    backgroundImage: bgImage8,
  },
  {
    icon: <FaCloud size={40} />,
    title: 'Cloud Integration',
    frontText: 'Cloud Integration',
    backText: 'Seamlessly integrate AI solutions with cloud services. Leverage the power of cloud computing for scalable and flexible AI deployments.',
    backgroundImage: bgImage9,
  },
  {
    icon: <FaMoneyCheck size={40} />,
    title: 'Financial Optimization',
    frontText: 'Financial Optimization',
    backText: 'Optimize financial processes with AI-driven insights. Improve accuracy and efficiency in financial forecasting and planning.',
    backgroundImage: bgImage10,
  },
  {
    icon: <FaBrain size={40} />,
    title: 'AI Research and Development',
    frontText: 'AI Research and Development',
    backText: 'Stay at the forefront of AI technology with our research and development services. Innovate and create cutting-edge AI solutions tailored to your business needs.',
    backgroundImage: bgImage11,
  },
  {
    icon: <FaHandshake size={40} />,
    title: 'Partnership and Collaboration',
    frontText: 'Partnership and Collaboration',
    backText: 'Collaborate with us to leverage our AI expertise for your projects. Build strong partnerships to drive innovation and achieve common goals.',
    backgroundImage: bgImage12,
  },
];

const Card = ({ card }) => {
  const [ref, inView] = useInView({ triggerOnce: false });

  return (
    <Col md={3} ref={ref}>
      <motion.div
        className="help-block"
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.5 }}
      >
        <div
          className="flip-card"
          style={{ backgroundImage: `url(${card.backgroundImage})` }}
        >
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <div className="flip-card-front-content">
                <h4>{card.title}</h4>
              </div>
            </div>
            <div className="flip-card-back">
              {card.icon}
              <h3>{card.frontText}</h3>
              <p>{card.backText}</p>
            </div>
          </div>
        </div>
      </motion.div>
    </Col>
  );
};

const HowWeCanHelp = () => {
  return (
    <div className="how-we-can-help-section">
      <Container>
        <Row className="text-center my-4">
          <Col>
            <h2>How We Can Help</h2>
            <Row>
              {cardData.map((card, index) => (
                <Card key={index} card={card} />
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HowWeCanHelp;
