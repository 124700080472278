import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import './Footer.css';
import logo from '../assets/logo2.png';
import { Link, useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  const handleNavClick = (path) => {
    navigate(path);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md={4}>
            <img src={logo} alt="ISIR.AI Logo" width="60" height="40" className="d-inline-block align-top" />
            <p>ISIR.AI is committed to delivering innovative AI solutions that transform industries and drive business growth.</p>
            <p><strong>Contact:</strong></p>
            <p>Email: info@isir.ai</p>
          </Col>
          <Col md={2}></Col>
          <Col md={3}>
            <ul className="list-unstyled">
              <h5>Quick Links</h5>
              <li><Link to="/" onClick={() => handleNavClick('/')}>Home</Link></li>
              <li><Link to="/about-us" onClick={() => handleNavClick('/about-us')}>About Us</Link></li>
              <li><Link to="/services" onClick={() => handleNavClick('/services')}>Services</Link></li>
              <li><Link to="/courses" onClick={() => handleNavClick('/courses')}>Courses</Link></li>
              <li><Link to="/careers" onClick={() => handleNavClick('/careers')}>Careers</Link></li>
              <li><Link to="/contact-us" onClick={() => handleNavClick('/contact-us')}>Contact Us</Link></li>
            </ul>
          </Col>
          <Col md={3}>
            <h5>Follow Us</h5>
            <ul className="list-inline">
              <li className="list-inline-item"><a href="https://www.facebook.com/ISIR.AITECH/" target="_blank" rel="noopener noreferrer"><FaFacebook /></a></li>
              <li className="list-inline-item"><a href="https://x.com/isiraitech" target="_blank" rel="noopener noreferrer"><FaXTwitter /></a></li>
              <li className="list-inline-item"><a href="https://www.instagram.com/isir.ai.tech/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a></li>
              <li className="list-inline-item"><a href="https://www.linkedin.com/company/isirai/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a></li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col className="text-center py-3">
            <p>&copy; {new Date().getFullYear()} ISIR.AI. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
