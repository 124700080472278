import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavigationBar from './components/Navbar';
import HomePage from './components/HomePage';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs';
import ServicesPage from './components/ServicesPage';
import Careers from './components/CareerPage';
import Courses from './components/Courses';
import ErrorBoundary from './components/ErrorBoundary';

const App = () => {
  return (
    <Router>
      <NavigationBar />
      <ErrorBoundary>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/courses" element={<Courses />} />
        </Routes>
      </ErrorBoundary>
      <Footer />
    </Router>
  );
};

export default App;
