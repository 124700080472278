import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './JoinUsSection.css';
import { Link } from 'react-router-dom';
import MyImageComponent from './MyImageComponent';
import teamImg from '../assets/webp/team.webp'; // Ensure the image is in WebP format

const JoinUsSection = () => {
  return (
    <div className="join-us-section">
      <Container>
        <Row className="align-items-center">
          <Col md={6} className="image-col">
            <div className="join-us-image">
              <MyImageComponent src={teamImg} alt="Join Us" className="join-us-img" />
            </div>
          </Col>
          <Col md={6} className="content-col">
            <div className="join-us-content">
              <h2>Join Us</h2>
              <hr className="divider" />
              <p>We are looking for bright talent in AI. Explore opportunities to start or grow your career with us.</p>
              <p>We offer:</p>
              <ul>
                <li>Exciting projects in various AI domains</li>
                <li>Opportunities for professional growth and development</li>
                <li>A collaborative and innovative work environment</li>
              </ul>
              <Button as={Link} to="/careers" className="joinus-hero-button">Explore Careers</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default JoinUsSection;
