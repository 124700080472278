import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import './Courses.css';
import MyImageComponent from './MyImageComponent';
import logo from '../assets/logo.png';
import energyImage from '../assets/energy-image.png'; 
import aiImage from '../assets/ai-image.png'; 

const CourseCard = ({ title, description, image, purpose, topicPlaceholder }) => {
  const [formData, setFormData] = useState({ email: '', topic: '', purpose: purpose });
  const [isFlipped, setIsFlipped] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!validateEmail(formData.email)) {
      newErrors.email = 'Please enter a valid email address.';
    }
    if (!formData.topic) {
      newErrors.topic = 'Please enter a topic of interest.';
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      emailjs.send('service_rzw4kjd', 'template_o5vf9oz', formData, 'QqeU4iSVC2c0kHP_C')
        .then((result) => {
          console.log(result.text);
          setIsFlipped(true);
        }, (error) => {
          console.log(error.text);
        });
    }
  };

  return (
    <Card className={`course-card ${isFlipped ? 'flipped' : ''}`}>
      <div className="course-card-inner">
        <MyImageComponent src={logo} alt="ISIR.AI Logo" width="60" height="40" className="d-inline-block align-top" />
        <div className="course-card-front">
          <MyImageComponent src={image} alt={title} className="card-img-top" />
          <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Text className="coming-soon">Coming Soon</Card.Text>
            <Card.Text>{description}</Card.Text>
            <Form onSubmit={handleSubmit} className="notify-form">
              <Form.Group controlId="formEmail" className="email-input-group">
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  isInvalid={!!errors.email}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formTopic" className="email-input-group">
                <Form.Control
                  type="text"
                  name="topic"
                  value={formData.topic}
                  onChange={handleChange}
                  placeholder={topicPlaceholder}
                  isInvalid={!!errors.topic}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors.topic}
                </Form.Control.Feedback>
              </Form.Group>
              <Button variant="primary" type="submit">Get Notified</Button>
            </Form>
          </Card.Body>
        </div>
        <div className="course-card-back">
          <Card.Body>
            <h4>Thank you for showing your interest!</h4>
            <p>You will be notified via email when the course is available. You can check back this page again.</p>
          </Card.Body>
        </div>
      </div>
    </Card>
  );
};

const Courses = () => {
  return (
    <Container className="courses-section my-5">
      <Row>
        <Col>
          <h1>Courses</h1>
          <p>Explore our upcoming courses in AI and Energy. Get notified when they are available.</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <CourseCard
            title="Energy Courses"
            description="Learn the latest in energy sector advancements."
            image={energyImage}
            purpose="Energy"
            topicPlaceholder="Enter the Topic of Interest for Energy Courses"
          />
        </Col>
        <Col md={6}>
          <CourseCard
            title="AI Courses"
            description="Explore the world of artificial intelligence."
            image={aiImage}
            purpose="AI"
            topicPlaceholder="Enter the Topic of Interest for AI Courses"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Courses;
