import React, { useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import EnhancedServicesSlider from './EnhancedServicesSlider';
import emailjs from 'emailjs-com';
import './ServicesPage.css';
import MyImageComponent from './MyImageComponent';

// Import images in WebP format
import cloud from '../assets/webp/cloud.webp';
import ai from '../assets/webp/ai.webp';
import dev from '../assets/webp/dev.webp';
import chat from '../assets/webp/chat.webp';
import data from '../assets/webp/data.webp';

const services = [
  {
    id: 'ai-consultancy',
    title: "AI Consultancy",
    description: "Transform your business with AI-driven strategies.",
    details: "Our AI consultancy services help businesses integrate AI technologies to improve operational efficiency, enhance customer experience, and drive innovation. We provide comprehensive AI solutions tailored to your business needs, including AI strategy development, implementation, and support. Our services also include MLOps for operationalizing machine learning models and LLMOps for managing large language models effectively.",
    image: ai,
  },
  {
    id: 'data-analytics',
    title: "Data Analytics",
    description: "Unlock the power of data with advanced analytics.",
    details: "Our data analytics services enable businesses to make data-driven decisions. We offer data collection, processing, and analysis services to help you gain valuable insights from your data. Our team of experts uses the latest tools and techniques to ensure accurate and actionable results.",
    image: data,
  },
  {
    id: 'chatbot-services',
    title: "Chatbot Services",
    description: "Enhance customer engagement with intelligent chatbots.",
    details: "Our chatbot services provide businesses with intelligent virtual assistants to handle customer inquiries, automate tasks, and improve engagement. We design, develop, and deploy chatbots that integrate seamlessly with your existing systems and provide a superior user experience.",
    image: chat,
  },
  {
    id: 'cloud-solutions',
    title: "Cloud Solutions",
    description: "Scalable and secure cloud integration services.",
    details: "Our cloud solutions offer businesses the flexibility and scalability they need to grow. We provide cloud integration, migration, and management services to ensure your business can leverage the full potential of cloud computing. Our team ensures secure and efficient cloud operations.",
    image: cloud,
  },
  {
    id: 'devops-services',
    title: "DevOps Services",
    description: "Streamline your development process with DevOps.",
    details: "Our DevOps services help businesses streamline their development and operations processes. We offer continuous integration, continuous delivery, and automation services to enhance productivity and reduce time-to-market. Our team ensures smooth collaboration between development and operations teams.",
    image: dev,
  },
];

const ServiceSection = ({ service }) => {
  const [ref, inView] = useInView({ triggerOnce: true });

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);

    const data = {
      name: formData.get('name'),
      email: formData.get('email'),
      phone: formData.get('phone'),
      message: formData.get('message'),
    };

    emailjs.send('service_rzw4kjd', 'template_sm8exeu', data, 'QqeU4iSVC2c0kHP_C')
      .then((result) => {
        console.log(result.text);
        alert('Thank you! Your request has been sent.');
        form.reset();
      }, (error) => {
        console.log(error.text);
        alert('An error occurred. Please try again.');
      });
  };

  return (
    <Container ref={ref} className="services-page-section" fluid>
      <Row className="align-items-center">
        <Col md={6} className="text-center">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }}
            transition={{ duration: 0.5 }}
          >
            <MyImageComponent src={service.image} alt={service.title} width="100%" height="auto" className="services-page-img-fluid" />
          </motion.div>
        </Col>
        <Col md={6}>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 50 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="services-page-title" id={service.id}>{service.title}</h2>
            <p className="services-page-description">{service.description}</p>
            <p className="services-page-details">{service.details}</p>
            <h4>Request a Call Back</h4>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" name="name" required />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" name="email" required />
              </Form.Group>
              <Form.Group controlId="formPhone">
                <Form.Label>Phone</Form.Label>
                <Form.Control type="text" name="phone" required />
              </Form.Group>
              <Form.Group controlId="formMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" name="message" rows={3} required />
              </Form.Group>
              <Button variant="primary" type="submit">Submit</Button>
            </Form>
          </motion.div>
        </Col>
      </Row>
    </Container>
  );
};

const ServicesPage = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          const offset = 100; // Adjust this value as needed
          const bodyRect = document.body.getBoundingClientRect().top;
          const elementRect = element.getBoundingClientRect().top;
          const elementPosition = elementRect - bodyRect;
          const offsetPosition = elementPosition - offset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      }, 500); // Adding a delay of 500ms
    }
  }, []);

  return (
    <div>
      <EnhancedServicesSlider />
      <Container className="services-page-summary my-5">
        <h2>Comprehensive AI Solutions</h2>
        <p>We provide a wide range of AI services to help your business achieve its goals. From AI consultancy to data analytics, our expert team is here to support you every step of the way.</p>
      </Container>
      {services.map((service, index) => (
        <ServiceSection key={index} service={service} />
      ))}
    </div>
  );
};

export default ServicesPage;
