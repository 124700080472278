import React from 'react';
import { Container, Row, Col, Button, Carousel } from 'react-bootstrap';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import Testimonials from './Testimonials';
import EnhancedServicesSlider from './EnhancedServicesSlider';
import IndustriesSection from './IndustriesSection';
import JoinUsSection from './JoinUsSection';
import HowWeCanHelp from './HowWeCanHelp';
import FeaturesGrid from './FeaturesGrid';
import banner2 from '../assets/webp/banner2.webp';
import banner3 from '../assets/webp/banner3.webp';
import banner5 from '../assets/webp/banner5.webp';
import banner8 from '../assets/webp/banner8.webp';
import banner9 from '../assets/webp/banner9.webp';

const HomePage = () => {
  return (
    <div>
      <div className="main-container">
        <div className="hero-section">
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={banner2}
                alt="AI Technology"
                title="AI Technology"
                fetchpriority="high"
                loading="eager"
              />
              <Carousel.Caption>
                <h1 className="hero-heading">Empowering Your Business with AI Solutions</h1>
                <Button as={Link} to="/contact-us" className="hero-button">Contact Us</Button>
                <Button as={Link} to="/services#ai-consultancy" className="hero-button">Learn More</Button>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={banner3}
                alt="AI and Data Analytics"
                title="AI and Data Analytics"
              />
              <Carousel.Caption>
                <h1 className="hero-heading">Advanced AI and Data Analytics</h1>
                <Button as={Link} to="/services#data-analytics" className="hero-button">Discover More</Button>
                <Button as={Link} to="/contact-us" className="hero-button">Contact Us</Button>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={banner8}
                alt="Chatbot Services"
                title="Chatbot Services"
              />
              <Carousel.Caption>
                <h1 className="hero-heading">Intelligent Chatbot Solutions</h1>
                <Button as={Link} to="/contact-us" className="hero-button">Contact Us</Button>
                <Button as={Link} to="/services#chatbot-services" className="hero-button">Learn More</Button>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={banner9}
                alt="AI Consultancy"
                title="AI Consultancy"
              />
              <Carousel.Caption>
                <h1 className="hero-heading">AI Consultancy for Your Business</h1>
                <Button as={Link} to="/services#ai-consultancy" className="hero-button">Get Started</Button>
                <Button as={Link} to="/contact-us" className="hero-button">Contact Us</Button>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={banner5}
                alt="Industry Solutions"
                title="Industry Solutions"
              />
              <Carousel.Caption>
                <h1 className="hero-heading">Transforming Industries with Cloud, Data, & AI</h1>
                <Button as={Link} to="/services#cloud-solutions" className="hero-button">Learn More</Button>
                <Button as={Link} to="/services#data-analytics" className="hero-button">Discover Solutions</Button>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
      <div className="highlight">
        <Container>
          <Row className="text-center my-4">
            <Col>
              <h2>Why Choose Us?</h2>
              <hr className="divider" />
              <h3>Intelligent Solutions, & Innovative Resources</h3>
              <hr className="divider" />
              <p>We offer customized AI solutions tailored to your business needs.</p>
              <p>Our team of experts ensures seamless integration and exceptional support.</p>
            </Col>
          </Row>
        </Container>
      </div>

      <EnhancedServicesSlider />
      <HowWeCanHelp />
      <IndustriesSection />
      <JoinUsSection />
    </div>
  );
};

export default HomePage;
