import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './ContactUs.css';
import MyImageComponent from './MyImageComponent'; // Import the updated MyImageComponent
import contact from '../assets/contact.png';
import emailjs from 'emailjs-com';
import logo from '../assets/logo.png';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    country: '',
    region: '',
    city: '',
    companyName: '',
    role: '',
    category: '',
    message: ''
  });

  const [errors, setErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    if (!validateEmail(formData.email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: 'Valid company email is required',
      }));
    } else {
      setErrors((prevErrors) => {
        const { email, ...rest } = prevErrors;
        return rest;
      });
    }
  }, [formData.email]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone: value
    });
  };

  const validateEmail = (email) => {
    const companyEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|net|org|edu|gov|ai|tech|io)$/;
    const personalEmailProviders = ['yahoo', 'outlook', 'hotmail', 'live', 'gmail'];
    const emailDomain = email.split('@')[1];

    if (!companyEmailRegex.test(email)) return false;
    return !personalEmailProviders.some((provider) => emailDomain.includes(provider));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email || !validateEmail(formData.email)) newErrors.email = 'Valid company email is required';
    if (!formData.phone) newErrors.phone = 'Phone number is required';
    if (!formData.country) newErrors.country = 'Country is required';
    if (!formData.region) newErrors.region = 'Region is required';
    if (!formData.city) newErrors.city = 'City is required';
    if (!formData.companyName) newErrors.companyName = 'Company name is required';
    if (!formData.role) newErrors.role = 'Role is required';
    if (!formData.category) newErrors.category = 'Category is required';
    if (!formData.message) newErrors.message = 'Message is required';

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      emailjs.send('service_rzw4kjd', 'template_sm8exeu', formData, 'QqeU4iSVC2c0kHP_C')
        .then((result) => {
          setFormSubmitted(true);
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
        });
    } else {
      alert('Please fill in all required fields correctly.');
    }
  };

  return (
    <div className="contact-us-section">
      <Container className="courses-section my-5">
        <h2 className='top-heading'>We are here to help</h2>
        <Row className="justify-content-md-center">
          <Col md={6} className="mb-4">
            <Card className={`contact-us-card ${formSubmitted ? 'flipped' : ''}`}>
              <MyImageComponent src={logo} alt="ISIR.AI Logo" width="60" height="40" className="d-inline-block align-top" />
              {formSubmitted ? (
                <Card.Body className="thank-you-message">
                  <MyImageComponent src={contact} alt="Thank You" className="card-img-top" />
                  <Card.Title>Thank You!</Card.Title>
                  <Card.Text>Your message has been sent. We will get back to you soon.</Card.Text>
                </Card.Body>
              ) : (
                <>
                  <MyImageComponent src={contact} alt="contact_us" className="card-img-top" />
                  <Card.Body className="contact-us-content">
                    <Card.Title>Get in Touch</Card.Title>
                    <Card.Text>
                      We are here to help you with any questions or support you need.
                      Please fill out the form, and our team will get back to you as soon as possible.
                    </Card.Text>
                  </Card.Body>
                </>
              )}
            </Card>
          </Col>
          {!formSubmitted && (
            <Col md={6}>
              <Card className="contact-us-form-card">
                <h2>Contact Us</h2>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formName" className="form-row">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formEmail" className="form-row">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                      required
                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                      title="Please enter a valid company email address."
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formPhone" className="form-row phone-input-container">
                    <Form.Label>Phone</Form.Label>
                    <PhoneInput
                      country={'us'}
                      value={formData.phone}
                      onChange={handlePhoneChange}
                      inputProps={{
                        name: 'phone',
                        required: true,
                        autoFocus: true
                      }}
                      containerClass="phone-input"
                      inputClass="form-control"
                    />
                    {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                  </Form.Group>

                  <Form.Group controlId="formCountry" className="form-row">
                    <Form.Label>Country</Form.Label>
                    <CountryDropdown
                      value={formData.country}
                      onChange={(val) => setFormData({ ...formData, country: val })}
                      classes="form-control"
                      required
                    />
                    {errors.country && <div className="invalid-feedback">{errors.country}</div>}
                  </Form.Group>

                  <Form.Group controlId="formRegion" className="form-row">
                    <Form.Label>Region</Form.Label>
                    <RegionDropdown
                      country={formData.country}
                      value={formData.region}
                      onChange={(val) => setFormData({ ...formData, region: val })}
                      classes="form-control"
                      required
                    />
                    {errors.region && <div className="invalid-feedback">{errors.region}</div>}
                  </Form.Group>

                  <Form.Group controlId="formCity" className="form-row">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      isInvalid={!!errors.city}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.city}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formCompanyName" className="form-row">
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleChange}
                      isInvalid={!!errors.companyName}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.companyName}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formRole" className="form-row">
                    <Form.Label>Role</Form.Label>
                    <Form.Control
                      as="select"
                      name="role"
                      value={formData.role}
                      onChange={handleChange}
                      isInvalid={!!errors.role}
                      required
                    >
                      <option value="">Select a role</option>
                      <option value="ceo">CEO</option>
                      <option value="founder">Founder</option>
                      <option value="cto">CTO</option>
                      <option value="manager">Manager</option>
                      <option value="ai-engineer">AI Engineer</option>
                      <option value="sales-staff">Sales Staff</option>
                      <option value="development-staff">Development Staff</option>
                      <option value="technical-staff">Technical Staff</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.role}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formCategory" className="form-row">
                    <Form.Label>Query Category</Form.Label>
                    <Form.Control
                      as="select"
                      name="category"
                      value={formData.category}
                      onChange={handleChange}
                      isInvalid={!!errors.category}
                      required
                    >
                      <option value="">Select a category</option>
                      <option value="services">Services</option>
                      <option value="products">Products</option>
                      <option value="sales">Sales</option>
                      <option value="jobseeker">Jobseeker</option>
                      <option value="other">Other</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.category}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formMessage" className="form-row">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows={4}
                      isInvalid={!!errors.message}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.message}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Form>
              </Card>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default ContactUs;
